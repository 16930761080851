import 'bootstrap'
import AOS from 'aos';
import Cookies from 'js-cookie';
import 'dropify/dist/js/dropify';

// cookie
window.Cookies = Cookies;
Cookies.set('page_index', true, { expires: 0.14 });

// JavaScript
$(document).ready( function () {

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // index privacy policy toggle
  $('.index .privacy-policy').click(function(){
      $(this).find('.title').toggleClass('active').siblings('.content').slideToggle(500);
  });

  var drEvent = $('.dropify').dropify({
    messages: {
      default: '拖拉檔案到這裡，或點擊本區塊後選擇檔案上傳',
      replace: '拖拉新檔案或再次點擊，可替換其他檔案',
      remove: '移除',
      error: '出錯了，請重新整理頁面'
    },
    error: {
      'fileSize': '檔案太大超過 {{ value }}',
      'imageFormat': '僅支援格式為 ({{ value }} 的檔案).',
      'fileExtension': '僅支援格式為 ({{ value }} 的檔案).'
    }
  });

  drEvent.on('dropify.afterClear', function(event, element){
    var attr_name = element.input.attr('name').substring(
      element.input.attr('name').lastIndexOf("[") + 1,
      element.input.attr('name').lastIndexOf("]")
    );
    $('<input>').attr({
      type: 'hidden',
      id: 'destroy_' + attr_name,
      name: 'destroy_' + attr_name
    }).appendTo('form');
  });
});

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    once: true,
  });
});
